<template>
  <b-container>
    <b-row class="pb-3 flex-lg-row align-items-center justify-content-center" :class="inline ? 'flex-lg-nowrap flex-row' : 'flex-column'">
      <b-col :cols="inline ? 6 : 12" :lg="inline ? 2 : 4"  v-for="(item, key) in items" class="item d-flex flex-column align-items-center"
             :key="key"
             :class="`${key === 0 && !inline ? 'mt-0 mt-lg-5' : 'mt-5'}`"
      >
        <a :href="item.link">
          <img :src="item.main_image_path" :alt="item.alt" class="item-img" :class="inline ? 'inline' : ''"/>
        </a>
        <h2 class="item-title text-blue jd-text-17 text-uppercase jd-font-bold" :class="inline ? 'inline text-left' : 'text-nowrap jd-text-24__mobile'">
          <a :href="item.link" class="text-blue">{{ item.title }}</a>
        </h2>
        <h4 class="item-description jd-text-18 d-none d-lg-block" v-if="!inline">{{ item.description }}</h4>
      </b-col>
    </b-row>

    <h2 class="welcome-text jd-text-18 mt-5 text-center d-none d-lg-block" v-if="!inline">{{ HOME_PAGE.welcome_text }}</h2>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ["items", "inline"],
  computed: {
    ...mapGetters(["HOME_PAGE"])
  }
}
</script>

<style scoped lang="scss">
.item {
  .item-img {
    position: relative;
    width: 162px;
    height: 162px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 40px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    border: 5px solid #fff;
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    transition: 0.3s ease;

    @media screen and (max-width: 992px){
      width: 131px;
      height: 131px;
    }
    &:hover {
      -webkit-filter: grayscale(0);
      filter: none;
    }

    &.inline {
      margin-bottom: 10px;
    }
  }

  .item-title {
    position: relative;
    width: 70%;
    &:after {
      content: "";
      display: block;
      width: 30%;
      height: 5px;
      background: #184782;
      border-radius: 150px;
      margin-top: 4px;
    }

    &.inline {
      padding-left: 15px;
      display: flex;
      align-items: center;
      text-align: left !important;
      justify-content: center;
    }

    &.inline:after {
      display: none;
    }

    &.inline:before {
      content: "";
      background: #184782;
      border-radius: 150px;
      left: 0;
      top: 0;
      width: 5px;
      height: 40px;
      display: block;
      margin-right: 10px;
      margin-left: -10px;
      @media screen and (max-width: 992px){
        height: 20px;
      }
    }

    @media screen and (max-width: 992px){
      width: 100%;
      text-align: center;
      &:after {
        display: none;
      }
    }
  }

  .item-description {
    width: 70%;
  }
}

.welcome-text {
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}
</style>
