<template>
    <div class="p-4 mb-4 position-relative overflow-hidden" :class="{'d-none d-lg-block' : mobileVideos, 'd-block d-lg-none' : desktopVideos}">
        <b-container>
            <h2 class="jd-text-32 text-uppercase jd-text-dark text-center jd-font-bold">{{ HOME_PAGE.video_title }}</h2>

            <b-row class="mt-4 justify-content-center">
                <b-col v-for="(video, key) in VIDEOS" :key="`video-${key}`"
                       cols="12"
                       lg="4"
                       class="c-pointer"
                       :class="{'d-none d-lg-block' : !video.is_mobile, 'd-block d-lg-none' : video.is_mobile}"
                       @click="showModal(video)"
                >
                    <span class="text-decoration-none">
                        <b-img :src="`https://img.youtube.com/vi/${video.video_id}/0.jpg`" class="w-100"></b-img>
                    </span>
                    <a :href="video.video_url" class="text-decoration-none" target="_blank">
                        <h5 class="jd-text-18 jd-text-dark jd-font-bold text-uppercase mt-3">{{ video.title }}</h5>
                    </a>
                    <p class="jd-text-18 jd-text-dark">
                        {{ video.description }}
                    </p>
                </b-col>
            </b-row>
        </b-container>

      <b-modal v-model="show" dialog-class="youtube--modal" size="xl" centered :key="url">
        <i class="fa fa-times close" @click="show = false;"></i>

        <iframe width="100%"
                height="100%"
                :src="url"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </b-modal>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    export default {
        props: {
            hideTriangle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
              show: false,
              url: null,
            }
        },
        beforeCreate() {
            this.$store.dispatch("GET_VIDEOS");
        },
        computed: {
            ...mapGetters(['VIDEOS', 'HOME_PAGE']),
            mobileVideos: function () {
                let videos = this.VIDEOS.filter((item) => item.is_mobile);
                return videos ? !videos.length : false;
            },
            desktopVideos: function () {
                let videos = this.VIDEOS.filter((item) => !item.is_mobile);
                return videos ? !videos.length : false;
            }
        },
      methods: {
        showModal(item) {
          this.show = true;
          this.url = item.video_url;
        }
      }
    }
</script>

<style scoped lang="scss">
.triangle--full__width{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}

::v-deep .youtube--modal {
  .close {
    position: absolute;
    top: -25px;
    right: 0;
    outline: none;
    text-shadow: none;
    color: #e8452a;
    opacity: 1;
    cursor: pointer;
  }
  background: transparent !important;
  .modal-header, .modal-footer {
    display: none;
  }

  .modal-body {
    padding: 0;
    height: 80vh;
    background: transparent;
    @media screen and (max-width: 762px) {
      height: 60vh;
    }
  }
}

.c-pointer {
  cursor: pointer;
}
</style>
