<template>
  <section>
    <!--Slider Section Start-->
    <slider :without-hero="true" :images="sliderImages" v-if="sliderImages.length"
            classes="service--slider"
            :sliderPageTitle="location.title"
    ></slider>
    <!--Slider Section End-->

<!--    <section class="headline-style d-none d-lg-block">-->
<!--      <section class="headline jd-text-dark py-2">-->
<!--        <b-container>-->
<!--          <p class="jd-text-18 m-0 jd-text-20__mobile jd-font-regular jd-home-text text-letter text-wrap-pre text-capitalize text-center" >-->
<!--            {{ location.title }}-->
<!--          </p>-->
<!--        </b-container>-->
<!--      </section>-->
<!--    </section>-->

    <b-container class="my-3">
      <section class="jd-text-18 text-styles">
        <section class="d-flex align-items-center mt-3">
          <b-img src="/img/icons/globus.png" class="contain-img mr-3"></b-img>
          <a :href="`${location.address}`" target="_blank" class="jd-text-dark">
            <span class="info--name jd-font-medium" >Address:</span> {{ location.address }}
          </a>
        </section>
        <section class="d-flex align-items-center mt-3">
          <b-img src="/img/icons/phone.svg" class="contain-img mr-3"></b-img>
          <a :href="`tel:${location.phone}`" target="_blank" class="jd-text-dark">
            <span class="info--name jd-font-medium" >Phone:</span> {{ location.phone }}
          </a>
        </section>

        <section class="d-flex align-items-center mt-3">
          <b-img src="/img/icons/globus.png" class="contain-img mr-3"></b-img>
          <a :href="`mailto:${location.email}`" target="_blank" class="jd-text-dark">
            <span class="info--name jd-font-medium" >Email:</span> {{ location.email }}
          </a>
        </section>
      </section>

      <section class="text-justify mt-5" v-html="location.description"></section>
    </b-container>


    <home-specialize :items="HOME_SPECIALIZES" class="mt-0 pt-3 mb-5"/>

    <h1 class="jd-text-light text-center jd-font-bold jd-text-29 jd-text-18__mobile mb-3">AREAS SERVED:</h1>

    <div class="map-container order-lg-0 order-0 mt-4 mt-lg-0 mb-2" @click="clickedMap = true" @mouseleave="clickedMap = false"
         :class="{ clicked : clickedMap }">
      <iframe width="100%" height="250" frameborder="0" style="margin-bottom:-6px"
              :src="`https://www.google.com/maps/embed/v1/place?q=${location.lat},${location.lon}&key=AIzaSyB2kXXO6fWPG_-L5IQXn96fx8qUg-_3GLk`"
              allowfullscreen></iframe>
    </div>

    <div class="my-5">
      <b-container>
        <b-row>
          <b-col v-for="(area, key) in location.areas" :key="key" cols="6" lg="4">
            <router-link :to="{ name: 'areaServed', params: { slug: area.url }}">
              <span class="jd-text-dark py-1 d-block jd-text-18 align-items-center d-flex">
                <img src="/img/icons/arrow_left.svg"  alt="" width="12px" class="mr-4"/>
                {{ area.city }}
            </span>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-row>
      <b-col cols="4" lg="2" class="p-0" v-for="(gallery, key) in galleryChunked()[0]" :key="`gallery-0-${key}`">
        <div @click="!gallery.hex_code ? index = gallery.galleryIndex : false"
             class="gallery-container">
          <div v-if="gallery.hex_code" class="gallery hex"
               :style="{'background-color': gallery.hex_code}"></div>
          <img :src="gallery.path" :alt="gallery.alt" title="" v-else class="gallery img"/>
        </div>
      </b-col>
    </b-row>

    <!--Video Section Start-->
    <jd-video></jd-video>
    <!--Video Section End-->

    <gallery :images="filteredGallery" :index="index" @close="index = null">
      <template v-slot:next>
        <b-img src="/img/icons/arrow_right.svg"></b-img>
      </template>

      <template v-slot:close>
        <b-img src="/img/icons/GalleryClose.svg"></b-img>
      </template>

      <template v-slot:prev>
        <b-img src="/img/icons/arrow_left.svg"></b-img>
      </template>
    </gallery>
  </section>
</template>

<script>
    import Services from "./../_partials/Home/Services";
    import Testimonials from "./../_partials/Home/Testimonials";
    import BannerRight from "./../_partials/Home/BannerRight";
    import BannerLeft from "./../_partials/Home/BannerLeft";
    import Slider from "./../_partials/Home/Slider";
    import Specialize from "./../_partials/Home/Specialize";
    import WeatherSection from "./../../components/weatherSection";
    import {RepositoryFactory} from "../../api/RepositoryFactory"
    import {mapGetters} from "vuex";
    import VueGallery from "vue-gallery";
    import carousel from 'vue-owl-carousel'
    import HomeSpecialize from "@/views/_partials/Home/HomeSpecialize";
    import JdVideo from "./../_partials/Home/Video";


    export default {
        metaInfo() {
            return {
                title: this.location.meta_title,
                meta: [
                    {
                        vmid: "description",
                        name: "description",
                        content: this.location.meta_description
                    },
                    {
                        vmid: "title",
                        name: "title",
                        content: this.location.meta_title
                    }
                ]
            };
        },
        components: {
          HomeSpecialize,
            Slider,
            Testimonials,
            Services,
            BannerRight,
            BannerLeft,
            Specialize,
            WeatherSection,
            'gallery': VueGallery,
            carousel,
          JdVideo
        },
        data() {
            return {
                slug: this.$route.params.slug,
                location: {},
                specialize: [],
                clickedMap: false,
                index: null
            }
        },
        beforeCreate() {
            this.$store.dispatch("GET_SERVICES_NAMES");
            this.$store.dispatch("GET_SPECIALIZE_DETAILS");
            this.$store.dispatch("GET_HOME_PAGE_INFO");
            this.$store.dispatch("GET_HOME_SPECIALIZE_DETAILS");
        },
        created() {
            RepositoryFactory.get('location').showLocations(this.slug).then(({data: {location}}) => {
                this.location = location;
                this.$root.$emit('hideLoader');
            });

            window.addEventListener("resize", () => {
                this.galleryHeight();
            })
        },
        methods: {
            chunkedPlaces: function () {
                if (!this.location.places || !this.location.places.length) return [[], []];

                const places = JSON.parse(JSON.stringify(this.location.places));
                return [
                    places.slice(0, 3),
                    places.slice(3, 6)
                ]
            },
            yelpPlaces() {
                if (!this.location.yelp_places || !this.location.yelp_places.length) return [[], []];

                const places = JSON.parse(JSON.stringify(this.location.yelp_places));
                return [
                    places.slice(0, 5),
                    places.slice(5, 10)
                ]
            },
            galleryChunked() {
                if (!this.location.gallery || !this.location.gallery.length) return [[], []];

                let galleries = JSON.parse(JSON.stringify(this.location.gallery));
                let index = 0;
                galleries.map((gallery) => {
                    gallery['galleryIndex'] = index;
                    if(!gallery.hex_code) index++;
                })
                return [
                    galleries.slice(0, 9),
                    galleries.slice(9, 18)
                ]
            },
            galleryHeight() {
                let item = document.querySelector(".gallery");
                if (item !== null) {
                    let items = document.querySelectorAll(".gallery")

                    for (let i = 0; i < items.length; i++) {
                        items[i].style.height = `${item.clientWidth}px`
                    }
                }
            }
        },
        updated() {
            this.galleryHeight();
        },
        computed: {
            ...mapGetters(['SERVICES', "SPECIALIZES", "HOME_SPECIALIZES"]),
            sliderImages: function () {
                if (!this.location.slider || !this.location.slider.length)
                    return [];

                return this.location.slider.map(item => item.path);
            },
            filteredGallery: function () {
                if(!this.location.gallery) return [];
                return this.location.gallery.filter((item) => !item.hex_code).map((item) => item.path);
            }
        },
        watch: {
            'SPECIALIZES': function (newVal) {
                this.specialize = this.chunk(newVal, Math.ceil(newVal.length / 2));
            }
        }
    }
</script>

<style lang="scss">
    @media screen and (max-width: 992px) {
        .slider--location {

            height: 45vh !important;

            .trinagle-bottom {
                height: 50px !important;
            }
        }

        .mt-mobile-0 {
            margin-top: 10px !important;
        }

        .brake-mobile {
            display: block;
        }
    }

    .jd-title-style {
        font-size: 27px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.37;
        letter-spacing: normal;
        text-align: center;
        color: #a0a0a0;
    }

    .place-item {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        &:after {
            content: "";
            width: 100px;
            display: none;
            margin-top: 20px;
            margin-bottom: 20px;
            height: 5px;
            border-radius: 150px;
            background-color: rgb(239, 78, 35);
            @media screen and (max-width:991px){
                display: block;
            }
        }
    }

    .gallery-container {
        .gallery {
            height: 166px !important;
            width: 100%;

            &.img {
                object-fit: cover;
            }
        }
    }

</style>

<style scoped lang="scss">
    /deep/.owl-carousel .owl-stage-outer {
        overflow: hidden !important;
    }

    /deep/.owl-carousel .owl-item  > div  {
        width: auto;
        height: 100%;
    }

    /deep/.next-yelp {
        background: url("/img/icons/arrow_right.svg") no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -15px;
        top: 55%;
        cursor: pointer;
    }

    /deep/.prev-yelp {
        background: url("/img/icons/arrow_left.svg") no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        left: -15px;
        top: 55%;
        cursor: pointer;
    }

    @media screen and (max-width: 992px) {
        .p-0__mobile {
            padding: 0;
        }
    }

    .opacity {
        color: #dedede;
    }

    .light-headline {
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #8e8e8e;
    }

    .text-box {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
    }


    .order--1 {
        order: -1;
    }

    .yelp--item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        h2 {
            text-align: center;
            color: #4a4a4a;
            font-size: 18px;
        }

        div {
            display: flex;

            img {
                width: 100px;
                height: 80px;
                object-fit: cover;
                border-radius: 5px;
                margin-right: 20px;
            }
        }
    }

    .headline {
        background: #a0a0a0;
        padding: 20px 0;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: center;
        color: #dedede;

        span:nth-child(1) {
            font-size: 27px;
            font-weight: bold;
            color: #4a4a4a;
        }

        span:nth-child(2) {
            font-size: 27px;
            font-weight: bold;
            color: #ef4e23;
        }

        span:nth-child(3) {
            font-size: 27px;
            font-weight: bold;
            color: #4a4a4a;
        }

        span:nth-child(4) {
            font-size: 27px;
            font-weight: bold;
            color: #ffffff;
        }

        span:nth-child(6) {
            color: #ffffff;
            opacity: 0.8;
        }
    }

    .b-l-1 {
        border-left: 2px solid #959595;
    }

    .map-container {
        width: 100%;
    }

    .map-container iframe {
        width: 100%;
        display: block;
        pointer-events: none;
        position: relative; /* IE needs a position other than static */
    }

    .map-container.clicked iframe {
        pointer-events: auto;
    }

    .mt-50px {
        margin-top: 50px;
    }

    .text--light {
        color: #a0a0a0;
        font-weight: 100;
    }

    .text-link {
        color: #0091ff;
    }
</style>


<style scoped lang="scss">
    /deep/.h-200px{
        height: 166px;
        object-fit: cover;
    }
    /deep/.blueimp-gallery{
        background: rgba(255,255,255,0.1);
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            backdrop-filter: blur(27px);
            filter: blur(27px);
            background-image: -webkit-gradient(linear, left top, left bottom, from(#444549), to(rgba(255, 255, 255, 0.5)));
            @-moz-document url-prefix() {
                filter: blur(27px);
                background-image: -webkit-gradient(linear, left top, left bottom, from(#67686c), to(rgba(255, 255, 255, 0.5)));
            }
        }
    }

    /deep/#blueimp-gallery {
        a.close {
            display: block;
            padding: 0;
            background: #fff;
            border-radius: 50%;
            border: 1px solid #EF4E23;
            margin: 5px 0;
            opacity: 0.6;
            transition: 0.3s ease;
            text-align: center;
            &:hover{
                opacity: 1;
            }
            img {
                width: 40px;
                height: 40px;
                transform: scale(1.08) translateX(-0.1px);
            }
        }
        a.next, a.prev{
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            border-color: #EF4E23;
            background: #fff;
            img{
                width: 20px;
                height: 20px;
            }
        }
    }



    .info--name {
      display: inline-block;
      width: 80px;
    }
</style>
